.simple-and-convenient {
    margin-top: 128px;

    h1 {
        color: $primary-color;
        font-family: $font-family;
        font-size: 64px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        margin-bottom: 67px;

        @media (max-width: 980px) {
            font-size: 48px;

        }

        span {
            position: relative;

            &::after {
                width: 100%;
                height: 3px;
                content: '';
                position: absolute;
                left: 0;
                bottom: 0;
                background: $secondary-color;

            }
        }

    }

    .card {
        border: none;
        border-radius: 48px;
        background: #FFF;
        box-shadow: 0 8px 8px 0 rgba(89, 95, 111, 0.04);

        img {
            width: 100%;
            height: auto;

            @media (max-width: 980px) {
                border-radius: 48px;
            }

        }

        .description {
            padding: 0 32px 0 0;

            @media (max-width: 980px) {
                padding: 26px;

            }

            h1 {
                color: $gray-color;
                font-family: $font-family;
                font-size: 36px;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
                margin-bottom: 0;
            }

            p {
                color: $gray-color;
                font-family: $font-family;
                font-size: 20px;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
                margin-top: 32px;
                margin-bottom: 0;
            }

        }

    }

}
