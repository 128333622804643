.navbar-primary {
    margin-top: 40px;
    margin-bottom: 40px;

    .signup-progress {
        display: flex;
        justify-content: space-between;

        @media (max-width: 980px) {
            display: block;

        }



        a {
            color: #A2ADB8;
            font-family: 'Roboto', sans-serif;
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            text-decoration: none;

            margin-right: 15px;
            padding-top: 15px;
            padding-bottom: 15px;
            border-bottom: 6px solid rgba(186,186,186,0.99);

            @media (max-width: 980px) {
                display: block;

            }


            &:hover, &.active {
                color: #0F0F0F;
                border-bottom: 6px solid #00704A;
            }

            &.complited {
                color: #00704A;
                border-bottom: 6px solid #00704A;
            }

        }
    }
}
