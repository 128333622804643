.faq {
    h1 {
        color: $white-color;
        font-family: $font-family;
        font-size: 64px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;

        @media (max-width: 980px) {
            font-size: 48px;

        }

    }

    &__image-box {
        width: 100%;
        height: 807px;
        background-size: cover;
        background-image: url("/frontend/img/bg-header.png");
        background-repeat: no-repeat;

    }

    &__items {
        padding-top: 151px;
        padding-bottom: 64px;

        background: #04171B;

        .accordion {
            margin-top: 64px;

            &-item {
                background: transparent;
                color: #FFF;
                font-family: "Yanone Kaffeesatz", sans-serif;
                font-size: 20px;
                font-style: normal;
                font-weight: 400;
                border: none;

                &:first-of-type {
                    border: none;
                    border-radius: 0;

                }

            }

            &-button {
                border: none;
                border-radius: 0;
                background: transparent;

                color: #FFF;
                font-family: "Yanone Kaffeesatz", sans-serif;
                font-size: 18px;
                font-style: normal;
                font-weight: 400;

                box-shadow: none;

                &::after {
                    width: auto;
                    height: auto;
                    content: "\f067";
                    color: #b5981f;
                    font-family: "FontAwesome";
                    background: none;
                }

                &:not(.collapsed) {
                    color: $white-color;
                    background-color: transparent;
                    box-shadow: none;

                    &::after {
                        width: auto;
                        height: auto;
                        color: #b5981f;
                        content: "\f068";
                        font-family: "FontAwesome";
                        background: none;
                    }

                }
            }

            &-collapse {
                width: 90%;
            }

            &-body {
                color: #FFF;
                font-family: "Yanone Kaffeesatz", sans-serif;
                font-size: 18px;
                font-style: normal;
                font-weight: 400;

            }
        }

    }

}
