header {
    padding: 32px 0;
    background: $default-background;

    .navbar-brand {
        display: block;

        svg {
            width: 100%;
            height: 47px;


        }
    }

    nav {
        ul {
            list-style: none;
            padding: 0;
            margin: 0;
            display: flex;
            align-items: center;

            li {
                margin-right: 32px;

                &:last-child {
                    margin-right: 0;
                }

                a {
                    color: $primary-color;
                    font-family: $font-family;
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 300;
                    line-height: normal;

                    &:hover {
                        color: $primary-color-hover;

                        svg {
                            path {
                                fill: $primary-color-hover;
                            }
                        }

                    }

                    svg {
                        path {
                            fill: $primary-color;
                            transition: all .4s ease;

                        }

                    }
                }
            }
        }
    }

    .btn-navbar-mobile {
        background: none;
        border: none;
        padding: 0;

    }

    .mobile-menu-wrapper {
        position: fixed;
        left: 0;
        top: 0;
        right: 0;
        bottom: 0;
        background: #fff;
        overflow: hidden;
        display: block;
        padding: 32px 0;
        z-index: 9999;
        transition: all 0.19s linear;
        transform: skewY(100deg) translateY(100%) scale(0);
        opacity: 0;

        &.show {
            //top: 0;
            opacity: 1;
            transform: skewY(0deg) translateY(0) scale(1);

        }

        &__navbar {
            display: block;
            padding: 0;
            padding-top: 64px;
            list-style: none;
            transition: all .4s ease;

            &.show {
                .mobile-menu-wrapper__navbar__nav-item {
                    opacity: 1;

                }
            }


            &__nav-item {
                margin-bottom: 18px;
                text-align: center;

                &:first-child {
                    margin-bottom: 64px;
                }

                &:last-child {
                    margin-top: 64px;
                }

                .nav-link {
                    display: block;
                    color: $primary-color;
                    font-family: $font-family;
                    font-size: 22px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: normal;
                    -webkit-font-smoothing: none;


                }

            }

        }

        .social {
            text-align: center;
            margin-top: 32px;

            a {
                margin-right: 10px;

                &:last-child {
                    margin-right: 0;
                }

                svg {

                }
            }

        }

    }


}
