.therapist-request {
    margin-top: 116px;

    &__about {
        margin-bottom: 64px;

        h1 {
            color: #0E2C21;
            font-family: "Yanone Kaffeesatz", sans-serif;
            font-size: 64px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
        }

        p {
            color: #3C3C3C;
            font-family: "Yanone Kaffeesatz", sans-serif;
            font-size: 20px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;

        }

        ul {
            li {
                color: #3C3C3C;
                font-family: "Yanone Kaffeesatz", sans-serif;
                font-size: 20px;
                font-style: normal;
                font-weight: 400;

            }
        }

    }

    &__how-to-start {
        margin-bottom: 64px;

        h1 {
            color: #0E2C21;
            font-family: "Yanone Kaffeesatz", sans-serif;
            font-size: 64px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
        }

        p {
            color: #3C3C3C;
            font-family: "Yanone Kaffeesatz", sans-serif;
            font-size: 20px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;

        }

    }

    &__technical-problems {
        margin-bottom: 64px;

        h1 {
            color: #0E2C21;
            font-family: "Yanone Kaffeesatz", sans-serif;
            font-size: 64px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
        }

        p {
            color: #3C3C3C;
            font-family: "Yanone Kaffeesatz", sans-serif;
            font-size: 20px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;

        }

    }

    &__survey {
        &-item {
            padding: 64px;
            border-radius: 32px;
            background: #FFF;
            box-shadow: 0 8px 8px 0 rgba(89, 95, 111, 0.04);

            @media (max-width: 980px) {
                padding: 48px 26px;

            }

            label {
                color: #3C3C3C;
                font-family: "Yanone Kaffeesatz", sans-serif;
                font-size: 20px;
                font-style: normal;
                font-weight: 400;
            }

            .form-control {
                color: #3C3C3C;
                font-family: "Roboto", sans-serif;
                font-size: 20px;
                font-style: normal;
                font-weight: 400;
            }

            &-form-description {
                color: #3C3C3C;
                font-family: "Yanone Kaffeesatz", sans-serif;
                font-size: 18px;
                font-style: normal;
                font-weight: 400;

                margin-top: 40px;
                margin-bottom: 0;

            }

            &-checkbox {
                margin-top: 32px;

                label {
                    display: flex;
                    align-items: center;
                    cursor: pointer;

                    span:not(.check-box) {
                        margin-left: 16px;
                        color: $gray-color;
                        font-family: $font-family;
                        font-size: 20px;
                        font-style: normal;
                        font-weight: 400;
                        line-height: normal;
                    }

                    input[type="checkbox"] {
                        display: none;

                        + .check-box {
                            position: relative;
                            display: inline-block;
                            width: $input-size;
                            height: $input-size;
                            border: $stroke-width solid $stroke-color;
                            border-radius: 5px;
                            flex-shrink: 0;
                            &:before {
                                content: '';
                                position: absolute;
                                top: 50%;
                                left: 50%;
                                transform: translate(-50%, -50%);
                                width: $dot-size;
                                height: $dot-size;
                                border-radius: 5px;
                                background-color: $stroke-color;
                                opacity: 0;
                                transition: opacity 0.2s ease-in-out;
                            }
                        }

                        &:checked + .check-box:before {
                            opacity: 1;

                        }
                    }

                    input[type="radio"] {
                        display: none;

                        + .check-box {
                            position: relative;
                            display: inline-block;
                            width: $input-size;
                            height: $input-size;
                            border: $stroke-width solid $stroke-color;
                            border-radius: 5px;
                            flex-shrink: 0;
                            &:before {
                                content: '';
                                position: absolute;
                                top: 50%;
                                left: 50%;
                                transform: translate(-50%, -50%);
                                width: $dot-size;
                                height: $dot-size;
                                border-radius: 5px;
                                background-color: $stroke-color;
                                opacity: 0;
                                transition: opacity 0.2s ease-in-out;
                            }
                        }

                        &:checked + .check-box:before {
                            opacity: 1;

                        }
                    }
                }

            }

            .button-box {
                display: flex;
                justify-content: center;
                text-align: center;

                margin-top: 64px;

                div {
                    width: 350px;

                }

            }

        }

    }
}
