.signup-pay {
    margin-bottom: 128px;

    &__card {
        @media (max-width: 980px) {
            text-align: center;

        }

        .card-header {
            margin-top: 64px;
            margin-bottom: 64px;


            img  {
                border-radius: 50%;
                width: 128px;

                @media (max-width: 980px) {
                    margin-bottom: 32px;

                }
            }

            &__title {
                font-family: $font-family;
                font-size: 22px;
                font-style: normal;
                font-weight: 400;
                line-height: normal;


                &__name {
                    font-family: $font-family;
                    font-size: 38px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: normal;

                }

            }

        }

        .card-body {
            &__price {
                font-family: $font-family;
                font-size: 20px;
                font-style: normal;
                font-weight: 400;
                line-height: normal;

                span {
                    font-family: 'Roboto', sans-serif;
                    font-size: 20px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: normal;

                }
            }

        }
    }

    .button-box {
        margin-top: 48px;
        margin-bottom: 48px;
        display: flex;
        justify-content: center;

        @media (max-width: 980px) {
            display: block;

        }


        .btn {
            @media (max-width: 980px) {
                width: 100%;

            }

            &-primary {
                display: block;
                padding: 9px 48px;
                text-align: center;
                border: 1px solid #00704A;

                &:hover {
                    background: transparent;
                    border: 1px solid #00704A;
                    color: #00704A;
                    width: 300px;

                    @media (max-width: 980px) {
                        width: 100%;

                    }


                }

                @media (max-width: 980px) {
                    span {
                        margin: auto;
                    }

                }



            }
        }
    }


    &.error {
        .card-body {
            margin-top: 64px;
            margin-bottom: 64px;

            .error-message {
                padding: 32px;
                background-color: rgba(168,10,0,0.15);
                font-family: 'Roboto', sans-serif;
                font-size: 20px;
                font-style: normal;
                font-weight: 400;
                line-height: normal;

                span {
                    font-family: 'Roboto', sans-serif;
                    font-size: 20px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: normal;

                }

            }
        }

        .button-box {
            margin-top: 48px;
            margin-bottom: 48px;
            display: flex;
            justify-content: center;

            @media (max-width: 980px) {
                display: block;
            }


            .btn {
                font-family: 'Roboto', sans-serif;
                font-size: 18px;
                font-style: normal;
                font-weight: 400;
                line-height: normal;

                @media (max-width: 980px) {
                    width: 100%;
                }


                &-primary {
                    display: block;
                    padding: 14px 48px;
                    text-align: center;
                    border: 1px solid #00704A;

                    &:hover {
                        background: transparent;
                        border: 1px solid #00704A;
                        color: #00704A;
                        width: 300px;

                        @media (max-width: 980px) {
                            width: 100%;
                        }



                    }

                    @media (max-width: 980px) {
                        span {
                            margin: auto;
                        }

                    }



                }
            }
        }
    }
}
