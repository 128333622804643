.auth {

    padding: 64px 0 100px 0;
    background-color: #E9ECE5;

    .btn-role-group {
        margin-bottom: 64px;

        a {
            color: #A2ADB8;
            font-family: 'Roboto', sans-serif;
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            text-decoration: none;

            margin-right: 15px;
            padding-top: 15px;
            padding-bottom: 8px;
            border-bottom: 6px solid rgba(186,186,186,0.99);

            @media (max-width: 980px) {
                display: block;

            }


            &:hover, &.active {
                color: #0F0F0F;
                border-bottom: 6px solid #00704A;
            }

        }
    }

    &-therapist {
        &-link {
            .btn {
                &-link {
                    font-size: 24px;
                }

            }
        }
    }

    .login-form {

        &__title {
            font-family: "Yanone Kaffeesatz", sans-serif;
            font-weight: 400;
            font-size: 48px;
            color: #141414;
            margin-bottom: 15px;

        }

        &__description {
            font-size: 20px;

        }

        &__form {
            max-width: 370px;
            margin: 40px auto;

            .form-control {
                text-align: left;
                padding: 12px 64px;
                color: $primary-color;
                font-family: Roboto, sans-serif;
                font-size: 18px;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
                background-color: rgba(255,255,255, 0.4);
                border-radius: 0.65rem;
                border: 1px solid #B5981F;
            }

            .iti {
                font-family: Roboto, sans-serif;
                font-size: 18px;
            }
            .iti--separate-dial-code .iti__selected-flag {
                border-top-left-radius: 0.65rem;
                border-bottom-left-radius: 0.65rem;


            }

            .button-box {
                margin-top: 32px;
                display: flex;
                justify-content: center;
                text-align:center;

                .btn {
                    &-primary {
                        padding: 8px 30px;
                        display: block;
                    }
                }
            }

        }

        &__terms {
            font-size: 20px;
        }


    }


}
