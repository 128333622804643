.signup-therapist-cards {
    margin-bottom: 96px;

    .therapist-info {
        display: grid;
        justify-content: center;
        text-align: center;

        &__image {
            img {
                width: 200px;
                height: auto;
                border-radius: 50%;

                @media (max-width: 980px) {
                    width: 128px;
                    margin-bottom: 32px;

                }


            }

        }

        &__title {
            font-family: $font-family;
            font-size: 22px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;

            &__name {
                font-family: $font-family;
                font-size: 38px;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
                margin-top: 40px;

                @media (max-width: 980px) {
                    font-size: 28px;
                    text-align: center;

                }


            }

            svg {
                width: 21px;
                height: 21px;
                margin-right: 5px;
            }

        }

        &__price {
            font-family: $font-family;
            font-size: 20px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            margin-top: 40px;

            span {
                font-family: 'Roboto', sans-serif;
                font-size: 20px;
                font-style: normal;
                font-weight: 500;
                line-height: normal;

            }
        }


        .button-box {
            margin-top: 48px;
            @media (max-width: 980px) {
                width: 100%;
                margin-bottom: 32px;

            }


            .btn {
                @media (max-width: 980px) {
                    width: 100%;

                }

                &-primary {
                    display: block;
                    padding: 9px 48px;
                    text-align: center;
                    border: 1px solid #00704A;

                    &:hover {
                        background: transparent;
                        border: 1px solid #00704A;
                        color: #00704A;
                        width: 300px;

                        @media (max-width: 980px) {
                            width: 100%;

                        }


                    }

                    @media (max-width: 980px) {
                        span {
                            margin: auto;
                        }

                    }


                }
            }
        }


    }


    .therapist-details {
        &__title {
            font-family: $font-family;
            font-size: 28px;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
            padding-bottom: 10px;

        }


        &__custom {
            font-family: 'Roboto', sans-serif;
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            margin-bottom: 40px;

            &__education {
                margin-top: 10px;
                margin-bottom: 20px;
            }

            .category-items {
                .category-group {
                    margin-bottom: 30px;

                    span {
                        font-family: $font-family;
                        font-size: 24px;
                        font-style: normal;
                        font-weight: 400;
                        line-height: normal;
                        color: #939393;

                    }

                    .category-item {
                        margin-top: 7px;
                        margin-bottom: 7px;

                        span {
                            font-family: 'Roboto', sans-serif;
                            font-size: 16px;
                            font-style: normal;
                            font-weight: 400;
                            line-height: normal;
                            color: #0F0F0F;

                        }

                        &__inactive {
                            span {
                                font-family: 'Roboto', sans-serif;
                                font-size: 16px;
                                font-style: normal;
                                font-weight: 400;
                                line-height: normal;
                                color: #c7c7c7;
                                text-decoration: line-through;

                            }
                        }


                    }
                }
            }
        }

        &__schedule {
            &__title {
                font-family: $font-family;
                font-size: 28px;
                font-style: normal;
                font-weight: 500;
                line-height: normal;
                padding-bottom: 10px;

            }

            &__timezone {
                font-family: 'Roboto', sans-serif;
                font-size: 16px;

                &__tz-icon {
                    width: 24px;
                    height: 24px;
                    margin-right: 8px;

                }

            }

            .client-schedule {
                margin-top: 20px;

                .cs-day {
                    .cs-day-header {
                        font-family: 'Roboto', sans-serif;
                        font-size: 16px;
                        font-style: normal;
                        font-weight: 500;
                        line-height: normal;

                    }

                    .cs-day-body {
                        margin-top: 5px;
                        margin-bottom: 32px;
                        display: contents;

                        .cs-slot {
                            margin-right: 10px;
                            margin-bottom: 10px;

                            input[type=radio] {
                                display: none;
                            }

                            input[type="radio"]:checked + .cs-slot-button {
                                color: #fff;
                                border: 1px solid #00704A;
                                background: #00704A;

                            }

                            .cs-slot-button {
                                font-family: 'Roboto', sans-serif;
                                font-size: 16px;
                                color: $primary-color;
                                border: 1px solid rgba(0, 112, 74, 0.18);
                                background-color: rgba(255,255,255, 0.2);
                                padding: 2px 24px;

                                &:hover {
                                    color: #fff;
                                    border: 1px solid #00704A;
                                    background: #00704A;
                                }
                            }

                        }
                    }
                }
            }

            &__button {
                margin-top: 50px;

                .button-box {
                    width: 350px;

                    @media (max-width: 980px) {
                        width: 100%;

                    }

                    .btn {

                        &:disabled, &.disabled {
                            color: $primary-color;
                            background-color: #d7d7d7;
                            border: 1px solid #d7d7d7;
                        }


                        &-primary {
                            display: block;
                            padding: 9px 48px;
                            text-align: center;
                            border: 1px solid #00704A;

                            &:hover {
                                background: transparent;
                                border: 1px solid #00704A;
                                color: #00704A;
                                width: 300px;

                                @media (max-width: 980px) {
                                    width: 100%;

                                }
                            }

                            @media (max-width: 980px) {
                                span {
                                    margin: auto;
                                }

                            }

                        }
                    }
                }
            }

        }

    }



}
