.what-can-psychologist {
    margin-top: 86px;

    h1 {
        color: $primary-color;
        font-family: $font-family;
        font-size: 64px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        margin-bottom: 67px;

        @media (max-width: 980px) {
            font-size: 48px;

        }

        span {
            position: relative;

            &::after {
                width: 100%;
                height: 3px;
                content: '';
                position: absolute;
                left: 0;
                bottom: 0;
                background: $secondary-color;

            }
        }

    }

    &__item {
        margin-bottom: 96px;

        h3 {
            color: $secondary-color;
            font-family: $font-family;
            font-size: 32px;
            font-style: normal;
            font-weight: 600;
            margin-bottom: 27px;

        }

        p {
            color: $primary-color;
            font-family: $font-family;
            font-size: 36px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
        }
    }

    .button-box {
        display: flex;
        justify-content: center;
        text-align: start;

        div {
            width: 350px;

        }

    }
}
