.contacts {
    margin-top: 128px;

    h1 {
        color: $primary-color;
        font-family: $font-family;
        font-size: 36px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        margin-right: 67px;
        padding-bottom: 0;

    }

    ul {
        margin: 0;
        padding: 0;
        list-style: none;
        display: flex;
        justify-content: space-between;
        align-items: center;

        @media (max-width: 980px) {
            margin-top: 32px;
            justify-content: center;
        }


        li {
            margin: 0;
            padding: 0;
            margin-right: 16px;

            &:last-child {
                margin-right: 0;
            }

            a {
                display: block;
                text-decoration: none;

                svg {
                    display: block;
                    margin-bottom: 8px;
                }

                span {
                    display: block;
                    color: #000;
                    text-align: center;
                    font-family: $font-family;
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: normal;
                }
            }
        }
    }
}
