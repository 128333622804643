.reviews {
    margin-top: 128px;

    h1 {
        color: $primary-color;
        font-family: $font-family;
        font-size: 64px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        margin-bottom: 67px;

        @media (max-width: 980px) {
            font-size: 48px;

        }

    }

    .card {
        border: none;
        border-radius: 48px;
        background: #FFF;
        box-shadow: 0 8px 8px 0 rgba(89, 95, 111, 0.04);

        padding: 32px;

        @media (max-width: 980px) {
            padding: 32px 18px;
        }


        img {
            width: 100%;
            height: auto;

            @media (max-width: 980px) {
                border-radius: 48px;
            }

        }

        .description {
            margin-top: 24px;

            p {
                color: $gray-color;
                font-family: $font-family;
                font-size: 20px;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
            }

            div {
                color: $gray-color;
                font-family: $font-family;
                font-size: 24px;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
                margin-top: 32px;
            }

        }

    }

    &__rating {
        display: flex;
        justify-content: end;
        align-items: center;

        svg {
            margin-right: 12px;

            &:last-child {
                margin-right: 0;
            }
        }
    }


}
