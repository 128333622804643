.when-required-consultation {
    margin-top: 86px;

    h1 {
        color: $primary-color;
        font-family: $font-family;
        font-size: 64px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        margin-bottom: 67px;

        @media (max-width: 980px) {
            font-size: 48px;

        }

        span {
            position: relative;

            &::after {
                width: 100%;
                height: 3px;
                content: '';
                position: absolute;
                left: 0;
                bottom: 0;
                background: $secondary-color;

            }
        }

    }

    &-radio {
        margin-top: 32px;

        &__items {
            display: flex;
            justify-content: left;
            align-items: center;

            @media (max-width: 980px) {
                display: block;

            }



            label {
                display: flex;
                align-items: center;
                cursor: pointer;
                margin-right: 64px;

                @media (max-width: 980px) {
                    margin-bottom: 16px;

                }


                span:not(.radio-box) {
                    margin-left: 16px;
                    color: $gray-color;
                    font-family: $font-family;
                    font-size: 20px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: normal;
                }

                input[type="radio"] {
                    display: none;

                    + .radio-box {
                        position: relative;
                        display: inline-block;
                        width: $input-size;
                        height: $input-size;
                        border: $stroke-width solid $stroke-color;
                        border-radius: 50%;
                        flex-shrink: 0;
                        &:before {
                            content: '';
                            position: absolute;
                            top: 50%;
                            left: 50%;
                            transform: translate(-50%, -50%);
                            width: $dot-size;
                            height: $dot-size;
                            border-radius: 50%;
                            background-color: $stroke-color;
                            opacity: 0;
                            transition: opacity 0.2s ease-in-out;
                        }
                    }

                    &:checked + .radio-box:before {
                        opacity: 1;

                    }
                }
            }
        }


    }

    &__items {
        margin-top: 70px;

        .card {
            padding: 32px;
            border: none;
            border-radius: 48px;
            background: #FFF;
            box-shadow: 0 8px 8px 0 rgba(89, 95, 111, 0.04);

            @media (max-width: 980px) {
                padding: 32px 18px;
            }


            img {
                width: 100%;
                height: auto;
                margin-bottom: 32px;

                img {
                    width: 100%;
                    height: auto;

                    @media (max-width: 980px) {
                        border-radius: 48px;
                    }

                }


            }

            .first-name, .last-name {
                color: $gray-color;
                font-family: $font-family;
                font-size: 24px;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
            }

            .experience {
                display: flex;
                align-items: center;
                margin-top: 16px;
                margin-bottom: 8px;

                span {
                    color: $gray-color;
                    font-family: $font-family;
                    font-size: 20px;
                    font-style: normal;
                    font-weight: 400;

                }

                svg {
                    margin-right: 8px;
                }

            }

            .seance-duration {
                color: $gray-color;
                font-family: $font-family;
                font-size: 20px;
                font-style: normal;
                font-weight: 400;
                margin-bottom: 8px;

            }

            .price {
                color: $gray-color;
                font-family: $font-family;
                font-size: 20px;
                font-style: normal;
                font-weight: 400;
                margin-bottom: 8px;
            }

            .seance-date {
                span {
                    color: $gray-color;
                    font-family: $font-family;
                    font-size: 20px;
                    font-style: normal;
                    font-weight: 400;
                }

                svg {
                    margin-right: 4px;
                }
            }

            .button-box {
                display: flex;
                justify-content: start;
                margin-top: 32px;

                @media (max-width: 980px) {
                    display: block;
                }


                div {
                    width: 100%;

                    .btn {
                        &-primary {
                            width: 250px;

                            @media (max-width: 980px) {
                                width: 100%;
                            }


                            &:hover {
                                width: 100%;

                            }
                        }
                    }
                }
            }

        }
    }


}
