// Fonts
@import url('https://fonts.googleapis.com/css2?family=Yanone+Kaffeesatz:wght@200..700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@100;200;300;400;500;600;700;800;900&display=swap');



// Bootstrap
@import "../../../node_modules/@fortawesome/fontawesome-free/css/all.min.css";
@import '~bootstrap';

@import "select2/dist/css/select2.css";


@import "general";
@import "sections/header";
@import "sections/hero";
@import "sections/what-can-psychologist";
@import "sections/how-psychologists-are-selected";
@import "sections/how-to-choose-a-suitable-psychologist";
@import "sections/when-required-consultation";
@import "sections/about";
@import "sections/simple-and-convenient";
@import "sections/reviews";
@import "sections/contacts";
@import "sections/promotion";
@import "sections/faq";
@import "sections/footer";
@import "sections/therapist-request";


@import "../../patients/sass/section/auth";
@import "../../patients/sass/section/signup/navbar-primary";
@import "../../patients/sass/section/signup/profile";
@import "../../patients/sass/section/signup/questions";
@import "../../patients/sass/section/signup/therapist";
@import "../../patients/sass/section/signup/therapist-cards";
@import "../../patients/sass/section/signup/signup-pay";



