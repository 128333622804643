.signup-profile {
    padding: 0;
    margin-bottom: 96px;

    .form-group {
        margin-bottom: 32px;
        display: flex;
        align-items: center;

        label {
            color: $primary-color;
            font-family: $font-family;
            font-size: 20px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;

        }

        .form-control {
            color: $primary-color;
            font-family: 'Roboto', sans-serif;
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            padding: 8px 24px;
            border: 1px solid rgba(0, 112, 74, 0.18);
            background-color: rgba(255,255,255, 0.4);
            border-radius: 6px;

        }

        .small {
            color: $primary-color;
            font-family: 'Roboto', sans-serif;
            font-size: 15px;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
            padding-left: 2px;
        }


    }




    &__choose-box {
        border-radius: 30px;
        padding: 3px;
        background: rgba(0, 112, 74, 0.18);
        display: inline-block;

        @media (max-width: 980px) {
            display: block;
            border-radius: 8px;
            justify-content: center;
            margin-bottom: 26px;

        }


        &__switch-button {
            display: inline-block;

            @media (max-width: 980px) {
                display: block;
                width: 100%;

            }


            label.hero-order-box-label {
                cursor: pointer;
                padding: 7px 30px;
                border-radius: 30px;
                transition: all .4s ease;

                color: $primary-color;
                font-family: 'Roboto', sans-serif;
                font-size: 16px;
                font-style: normal;
                font-weight: 400;
                line-height: normal;


                @media (max-width: 980px) {
                    width: 100%;
                    border-radius: 8px;

                    text-align: center;
                    padding: 15px 30px;
                }



            }

            input[type="radio"].hero-order-box-input {
                display: none;

                &:checked + label.hero-order-box-label {
                    background: #fff;

                    @media (max-width: 980px) {
                        width: 100%;
                    }


                }

            }

        }

    }



    .button-box {
        width: 350px;

        @media (max-width: 980px) {
            width: 100%;

        }


        .btn {
            &-primary {
                display: block;
                padding: 9px 48px;
                text-align: center;
                border: 1px solid #00704A;


                &:hover {
                    background: transparent;
                    border: 1px solid #00704A;
                    color: #00704A;
                    width: 300px;

                    @media (max-width: 980px) {
                        width: 100%;

                    }


                }


                @media (max-width: 980px) {
                    span {
                        margin: auto;
                    }
                }


            }
        }
    }

}
