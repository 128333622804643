footer  {
    padding-top: 64px;
    padding-bottom: 64px;
    background: #04171B;

    .wrapper {
        //border-top: 1px solid #999;

        padding-top: 64px;

        ul {
            list-style: none;
            display: flex;
            justify-content: space-between;
            margin-bottom: 0;

            @media (max-width: 980px) {
                display: block;
                padding-left: 0;

                margin-top: 32px;
                margin-bottom: 32px;
            }

            li {
                width: 150px;

                @media (max-width: 980px) {
                    width: 100%;
                }


                &:last-child {
                    width: 300px;

                    @media (max-width: 980px) {
                        width: 100%;
                    }

                }

                a {
                    color: $white-color;
                    font-family: $font-family;
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 300;
                    line-height: normal;
                }
            }
        }

        .copyright {
            color: $white-color;
            font-family: $font-family;
            font-size: 16px;
            font-style: normal;
            font-weight: 300;
            line-height: normal;
            text-align: center;
            margin-top: 64px;
        }
    }
}
