$font-family: "Yanone Kaffeesatz", sans-serif;

$default-background: #E9ECE5;
$primary-color: #0E2C21;
$primary-color-hover: #00704A;
$secondary-color: #B5981F;
$white-color: #fff;
$gray-color: #3C3C3C;

$input-size: 32px;
$dot-size: 24px;
$stroke-width: 1px;
$stroke-color: $secondary-color;


body {
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

* {
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}


body {
    color: $primary-color;
    font-family: $font-family;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    background: #E9ECE5;
    font-optical-sizing: auto;

}

a {
    color: $primary-color;
    text-decoration: none;
    transition: all .4s ease;

    &:hover {
        color: $primary-color-hover;
    }
}


a, button {
    outline: none;
    border: none;

    &:focus {
        outline: none;
        border: none;
        box-shadow: none;

    }
}

.btn {
    padding: 0;
    border: none;
    border-radius: 32px;

    @media (max-width: 980px) {
        width: 100%;

    }


    &:disabled, &.disabled {
        color: $primary-color;
        background-color: #d7d7d7;
        border: 1px solid #d7d7d7;
    }



    &-link {
        color: $primary-color;
        font-family: $font-family;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        text-decoration-line: underline;
        border-radius: 0;

        &:active,
        &:focus,
        &:focus-visible {
            color: $primary-color-hover !important;

        }

        &:hover {
            color: $primary-color-hover;
            text-decoration-line: none;

        }
    }

    &-default {
        padding: 12px 16px 8px 48px;
        background: transparent;
        color: $gray-color;
        display: inline-flex;
        align-items: center;

        span {
            text-align: center;
            font-family: $font-family;
            font-size: 24px;
            font-style: normal;
            font-weight: 400;

        }

        svg {
            margin-left: 32px;
            transition: all .4s ease;
        }

        &:active,
        &:focus,
        &:focus-visible {
            background: transparent !important;

        }

        &:hover {
            background: transparent;

            svg {
                margin-left: 48px;

                @media (max-width: 980px) {
                    margin-left: 32px;
                }


            }

        }



    }

    &-primary {
        padding: 9px 16px 8px 48px;
        color: $white-color;
        display: flex;
        align-items: center;
        position: relative;
        transition: all .4s ease;
        width: 300px;
        border: 1px solid #00704A;
        background: #00704A;

        @media (max-width: 980px) {
            width: 100%;
            padding: 8px 16px;
            justify-content: center;
            text-align: center;
        }


        span {
            text-align: center;
            font-family: $font-family;
            font-size: 24px;
            font-style: normal;
            font-weight: 400;

            @media (max-width: 980px) {
                margin: 0;
            }



        }

        svg {
            position: absolute;
            right: 32px;
            margin-left: 32px;
            transition: all .4s ease;

            @media (max-width: 980px) {
                margin-left: 8px;
            }

            path {
                fill: #fff;
            }
        }

        &:active,
        &:focus,
        &:focus-visible {
            color: #fff;
            background: #00704A !important;

        }

        &:hover {
            background: #00704A;
            width: 100%;

            svg {
                margin-left: 48px;
                @media (max-width: 980px) {
                    margin-left: 16px;
                }


            }

        }



    }
}


.button-box {
    div {
        @media (max-width: 980px) {
            width: 100%;
        }

    }

}

.form-control {
    &:focus,
    &:focus-visible {
        box-shadow: none;
    }
}

.form-check {
    margin-right: 10px;
    padding-left: 0;

    input[type="checkbox"] {
        display: none;
    }

    label {
        color: $primary-color;
        font-family: 'Roboto', sans-serif;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        display: flex;
        align-items: center;
        cursor: pointer;
    }

    .custom-checkbox {
        display: inline-flex;
        align-items: center;
        cursor: pointer;
        margin-right: 8px;

        svg {
            width: 24px;
            height: 24px;
        }

        .unchecked-icon {
            display: block;
        }

        .checked-icon {
            display: none;
        }
    }

    input[type="checkbox"]:checked + label .custom-checkbox {
        .unchecked-icon {
            display: none;
        }

        .checked-icon {
            display: block;
        }
    }
}


.select2-container .select2-selection--single {
    font-family: 'Roboto', sans-serif;
    font-size: 16px;
    padding: 8px 24px !important;
    height: 38px !important;
    background-color: rgba(255,255,255, 0.4);
    border-radius: 6px;
    border: 1px solid rgba(0, 112, 74, 0.18);

}
