.signup-therapist {
    margin-top: 52px;
    margin-bottom: 96px;

    &__card {
        position: relative;
        padding: 32px;
        border-radius: 6px;
        border: 1px solid rgba(0, 112, 74, 0.18);
        background-color: rgba(255,255,255, 0.4);

        &:hover {
            background-color: rgba(255,255,255, 0.6);
        }

        img {
            height: auto;
            border-radius: 50%;

            @media (max-width: 980px) {
                width: 128px;
                margin-bottom: 32px;

            }


        }


        &__title {
            color: $primary-color;
            font-family: $font-family;
            font-size: 20px;
            font-style: normal;
            font-weight: 400;


            h3 {
                color: $primary-color;
                font-family: $font-family;
                font-size: 24px;
                font-style: normal;
                font-weight: 500;
            }

            span {
                color: $primary-color;
                font-family: 'Roboto', sans-serif;
                font-size: 16px;
                font-style: normal;
                font-weight: 400;

            }
        }


    }

    .btn {
        margin-top: 16px;

        &-link {
            display: block;
            text-align: left;

            span {
                color: $primary-color;
                font-family: $font-family;
                font-size: 20px;
                font-style: normal;
                font-weight: 500;


            }
        }
    }
}
