.hero {
    margin-top: 113px;

    &__title1 {
        color: $primary-color;
        font-family: $font-family;
        font-size: 24px;
        font-style: normal;
        font-weight: 300;
        line-height: normal;
    }

    &__title2 {
        color: $primary-color;
        font-family: $font-family;
        font-size: 64px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;

        @media (max-width: 980px) {
            font-size: 48px;

        }


        span {
            color: $primary-color-hover;
        }
    }

    &__image-box {
        width: 100%;
        height: 807px;
        background-size: cover;
        background-image: url("/frontend/img/bg-header.png");
        background-repeat: no-repeat;

    }

    &__order-box {
        //display: inline;
        padding-top: 50px;

        &__choose-box {
            border-radius: 30px;
            padding: 3px;
            background: rgba(0, 112, 74, 0.18);
            display: inline-block;

            @media (max-width: 980px) {
                display: flex;
                justify-content: center;
                margin-bottom: 16px;

            }


            &__switch-button {
                display: inline-block;

                @media (max-width: 980px) {
                    width: 49%;

                }


                label.hero-order-box-label {
                    cursor: pointer;
                    padding: 15px 60px;
                    border-radius: 30px;
                    transition: all .4s ease;

                    color: $primary-color;
                    font-family: $font-family;
                    font-size: 24px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: normal;

                    @media (max-width: 980px) {
                        width: 100%;

                        text-align: center;
                        padding: 15px 30px;
                    }



                }

                input[type="radio"].hero-order-box-input {
                    display: none;

                    &:checked + label.hero-order-box-label {
                        background: #fff;

                        @media (max-width: 980px) {
                            width: 100%;
                        }


                    }

                }

            }

        }

        .button-box {
            display: flex;
            justify-content: left;

            div {
                width: 350px;

            }

        }
    }

    &__advantages {
        padding: 136px 0;
        background: #04171B;
        border-radius: 0 0 64px 64px;

        &__item {
            text-align: center;

            h1 {
                color: $secondary-color;
                font-family: $font-family;
                font-size: 64px;
                font-style: normal;
                font-weight: 400;

            }

            p {
                color: $white-color;
                font-family: $font-family;
                font-size: 28px;
                font-style: normal;
                font-weight: 300;
                line-height: normal;


            }
        }
    }
}
