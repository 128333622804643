.how-to-choose-a-suitable-psychologist {
    margin-top: 64px;

    &__items {
        padding: 64px;
        border-radius: 32px;
        background: #FFF;
        box-shadow: 0 8px 8px 0 rgba(89, 95, 111, 0.04);

        @media (max-width: 980px) {
            padding: 48px 26px;

        }


        h1 {
            color: $primary-color;
            font-family: $font-family;
            font-size: 64px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;

            @media (max-width: 980px) {
                font-size: 48px;

            }

        }

        &-tabs {
            list-style: none;
            display: flex;
            justify-content: space-between;
            text-align: center;


            padding: 0;
            margin-top: 40px;
            margin-bottom: 32px;

            @media (max-width: 980px) {
                display: block;
                width: 100%;
            }


            li {
                width: 33.33%;
                display: block;
                position: relative;
                padding-top: 16px;

                @media (max-width: 980px) {
                    width: 100%;
                    margin-bottom: 16px;
                }


                &::after {
                    width: 100%;
                    position: absolute;
                    content: '';
                    background: #d9d9d9;
                    height: 5px;
                    top: 0;
                    left: 0;

                    @media (max-width: 980px) {
                        top: auto;
                        bottom: -15px;
                    }


                }

                &.active {
                    &::after {
                        width: 100%;
                        position: absolute;
                        content: '';
                        background: #0E2C21;
                        height: 10px;
                        top: 0;
                        left: 0;

                        @media (max-width: 980px) {
                            top: auto;
                            bottom: -15px;
                        }


                    }
                }


                a {
                    display: block;
                    color: $gray-color;
                    font-family: $font-family;
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: normal;
                    text-align: center;
                }
            }

        }

        h2 {
            color: $gray-color;
            font-family: $font-family;
            font-size: 36px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            text-align: center;

        }

        &-radio {
            margin-top: 32px;

            label {
                display: flex;
                align-items: center;
                cursor: pointer;

                span:not(.radio-box) {
                    margin-left: 16px;
                    color: $gray-color;
                    font-family: $font-family;
                    font-size: 20px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: normal;
                }

                input[type="radio"] {
                    display: none;

                    + .radio-box {
                        position: relative;
                        display: inline-block;
                        width: $input-size;
                        height: $input-size;
                        border: $stroke-width solid $stroke-color;
                        border-radius: 50%;
                        flex-shrink: 0;
                        &:before {
                            content: '';
                            position: absolute;
                            top: 50%;
                            left: 50%;
                            transform: translate(-50%, -50%);
                            width: $dot-size;
                            height: $dot-size;
                            border-radius: 50%;
                            background-color: $stroke-color;
                            opacity: 0;
                            transition: opacity 0.2s ease-in-out;
                        }
                    }

                    &:checked + .radio-box:before {
                        opacity: 1;

                    }
                }
            }

        }

    }

    .button-box {
        display: flex;
        justify-content: end;
        text-align: center;

        div {
            width: 350px;

        }

    }

}
