.promotion {
    margin-top: 128px;

    .card {
        border: none;
        border-radius: 48px;
        box-shadow: 0 8px 8px 0 rgba(89, 95, 111, 0.04);
        background: #FFF;
        margin-bottom: 32px;
        padding: 32px;

        @media (max-width: 980px) {
            padding: 32px 18px;
        }


        h1 {
            color: $primary-color;
            font-family: $font-family;
            font-size: 36px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;

            @media (max-width: 980px) {
                font-size: 28px;
                margin-bottom: 32px;
            }


        }

        .button-box {
            display: flex;
            justify-content: end;
            text-align: end;

            div {
                display: flex;
                justify-content: end;
                width: 350px;

            }

        }

    }
}
