.about {
    margin-top: 64px;

    &__items {
        padding: 64px;
        border-radius: 32px;
        background: #FFF;
        box-shadow: 0 8px 8px 0 rgba(89, 95, 111, 0.04);

        @media (max-width: 980px) {
            padding: 48px 26px;

        }


        h1 {
            color: $primary-color;
            font-family: $font-family;
            font-size: 64px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            margin-bottom: 67px;

            @media (max-width: 980px) {
                font-size: 48px;

            }


            span {
                position: relative;
                display: table-cell;

                &::after {
                    width: 100%;
                    height: 3px;
                    content: '';
                    position: absolute;
                    left: 0;
                    bottom: 0;
                    background: $secondary-color;

                }
            }

        }

        p {
            color: $gray-color;
            font-family: $font-family;
            font-size: 36px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
        }

        &-review {
            margin-top: 32px;

            p {
                color: $gray-color;
                font-family: $font-family;
                font-size: 24px;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
            }

        }

    }

}
